import React ,{useEffect} from "react";
import Navbar from "./components/nav/nav-bar.jsx";
import Home from "./pages/home/home.jsx";
import RealEstatePage from "./pages/real-estate/real_estate";
import ContactUsPage from "./pages/contact-us/contact_us";
import ApplicationsPage from "./pages/applications/applications";
import PortfolioPage from "./pages/portfolio/portfolio";

import Footer from "./components/footer/footer"
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import ScrollToTop  from "./components/utility-components/scroll-to-top";
import ScrollToTopF from "./components/scroll-to-top/scroll-to-top";
import "./index.css";

function App() {

  return (
    <Router>
      <div className="App">
        <div className="background"></div>
        <Navbar />
        <ScrollToTop/>
        <Switch>
          <Route path="/RealEstate" component={RealEstatePage} />
          <Route path="/ContactUs" component={ContactUsPage} />
          <Route path="/Applications" component={ApplicationsPage} />
          <Route path="/Portfolio" component={PortfolioPage} />
          <Route exact path="/" component={Home} />
        </Switch>
        {/* <ScrollToTopF></ScrollToTopF> */}
      <Footer />
      </div>
    </Router>
  );


}


export default App;

//TODO Write Tests
