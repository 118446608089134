import React from 'react'
import { ContactButton } from "../../components/button/button-style"
import "../../styles/text_styles.css"

export default function RealEstatePage() {

  
  return (
    <div className="page_container">
        <div className="flex_side">
            <div className="t_block_container single">
                <h3>Real Estate Visualization</h3>
                <br></br>
                <p className="t_block_text"> 
                Using state of the art technology, we can bring your <span>real estate projects</span> to life before the first brick is even laid. 
                </p>
            </div>


            <img className="image_block" src={require("../../images/general-images/tour.jpg")}>
            </img>
        </div>
        <div className="t_block_container">
        <p className="t_block_text">From the 3D modeling stage to a fully functional Virtual Reality <span>experience</span>, we will carry your project start to finish.</p>
        <p className="t_block_text">Clients can <span>walk</span> around their home in <span>Virtual Reality</span> and make design decision in real time.</p>
        <p>Have any questions or want to learn more about our process? Contact us!</p>
        </div>
        <ContactButton to="/ContactUs">Contact Us</ContactButton>
    </div>
  )
}
