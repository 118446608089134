import React from 'react'
import { ContactButton } from "../../components/button/button-style"
import "../../styles/text_styles.css"
import "./portfolio.css"

export default function PortfolioPage() {

  
  return (
    <div className="page_container">
      <div className='port_block'>
          <div className="port_text_container">
            <div className="t_block_container single">
                <h3>Nordbron Booth</h3>
                <br></br>
                <p className="t_block_text"> 
                3D Realtime visualisation of a booth for a trade show.
                </p>
            </div>
          </div>

          <img className="port_image_block" src={require("../../images/portfolio-images/nord.jpg")}></img>
      </div>

      <div className='port_block'>
          <div className="port_text_container">
            <div className="t_block_container single">
                <h3>The Approach</h3>
                <br></br>
                <p className="t_block_text"> 
                Design and 3D Realtime visualisation of the Approach in Troy, NY.
                </p>
            </div>
          </div>

          <img className="port_image_block" src={require("../../images/portfolio-images/approach.jpg")}></img>
      </div>




        <br></br>
        <br></br>

        <ContactButton to="/ContactUs">Contact Us</ContactButton>
        <br></br>
        <br></br>
     
    </div>
  )
}
