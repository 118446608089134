import React from "react";
import {FooterContainer, FooterLink, FooterPlaceHolder} from "./footer-style"


export default class Footer extends React.Component {

    render(){
        return(
            <div>
            <FooterPlaceHolder></FooterPlaceHolder>
            <FooterContainer>
                <FooterLink>LumenViz&#174;</FooterLink>
            </FooterContainer>
            </div>
        );
    }
}