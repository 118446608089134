import React from 'react'
import SlideShow from "../../components/slide-show/slide-show"
import ImageBlock from "../../components/image-block/image-block"
import NewsLetterForm from "../../components/newsletter-form/newsletter-form"
import { RoundButton, ContactButton, SubmitButton } from "../../components/button/button-style"
import { TextContainer, LightTextContainer, DownloadText, EmailText } from "../../components/text-block/text-container-style"
import { Divider, TransparentDivider, FlexBox } from "../../components/utility-styles/utility-styles"
import { TextInput } from "../../components/input/input-style"
import { InfoCard, InforCardContainer, CardHeader } from "../../components/info-card/info-card"
import { Link } from "react-router-dom"
import ScrollToTopF from "../../components/scroll-to-top/scroll-to-top";
// import ScrollToTop  from "../../components/utility-components/scroll-to-top";
import scrollToTopF from "../../utility/utility_functions";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import "../home/home.css"


/**
 * Home page
 */

export default class Home extends React.Component {

    constructor(props) {
        super(props)
        this.emailInput = React.createRef();
        this.educCard = React.createRef();
        this.slideImages = this.importImages(require.context("../../images/slideshow-images", false, /.jpg|.png/));
        this.cardImages = this.importImages(require.context("../../images/cards", false, /.jpg|.png/,));

        //TODO make utility
    }

    render() {
        return (
            <div>
                <SlideShow />
                <Divider height="18px" />
                
                <LightTextContainer styles="margin-top:25px; max-width:450px;" font_size="20px" mobile_size="20px">
                    <p>The future of <span>visualization</span> is here.
                    </p>
                </LightTextContainer>

                <LightTextContainer font_size="18px" mobile_size="17px" styles="margin-top:0px; margin-bottom:0px; max-width:800px;">
                    <p>
                    Express <span>ideas</span> and <span>projects</span> in a totally immersive virtual environment with <span>Virtual Reality</span>. <br></br>Stay ahead of the game with <span>next-gen</span> immersive visualizations.
                    </p>
                </LightTextContainer>
          

                <ImageBlock styles="margin-top:60px;" side="right" image={require("../../images/general-images/bus_vr.jpg")} ref={this.educCard}>
                {/* <div ref={this.educCard}></div> */}
                It can be hard to express ideas to clients. <br/><span>Virtual Reality</span> lets you put your clients directly inside of a 1 to 1 scale life like environment where they can fully explore projects. <br/>No detail goes <span>un-noticed</span> in VR. 
                </ImageBlock>

                <ImageBlock side="left" image={require("../../images/general-images/Lumen.gif")}>
                Have your clients tour their <span>dream home</span> project in a life-like Virtual Reality experience where they can tweak and make adjustments as they walk around in real time.
                </ImageBlock>

                <ImageBlock side="right" image={require("../../images/general-images/customvr.jpg")}>
                We do more than just visualization. <br/> 
                Have an <span>idea</span> that you'd like to turn into a <span>VR Application?</span> <br/>We've got you covered! 

                </ImageBlock>
           
                <DownloadText font_size="18px" mobile_size="17px" styles=" margin-bottom:14px; max-width:300px;">
                    <p>
                    Want to work with us?
                    </p>
                </DownloadText>

                <ContactButton to="/ContactUs">Contact Us</ContactButton>

                <ScrollToTopF></ScrollToTopF>

            </div>
        );

    }

    /**
     * Imports images from a folder and returns an array containing the images. 
     * @param {array} images : Object containing images.
     */
    importImages(images) {
        let imageArray = [];
        images.keys().forEach((element, i) => {
            imageArray[i] = images(element);
        });
        return imageArray;
    }

    jumpToElement = (element) =>{
        console.log(this.educCard);
        this.educCard.current.scrollMark.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }

}
