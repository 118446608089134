import styled, { css } from "styled-components"
import {device} from "../../device-sizes"

/**Text container, used with a p tag and optional h header tag containing text inside. */
export const TextContainer = styled.div`

    margin: 50px auto 10px auto;
    width:60%;
    min-width: 200px;
    height: auto;
    background-color: rgb(29, 29, 29);
    text-align:center;
    border-radius: 15px;
    
    & p{
        color: #ffffff;
        /* padding: calc(1% + 3px); */
        padding: 15px;
        font-family: 'Raleway', sans-serif;
        font-size: ${props => props.font_size ? props.font_size : "17px"};
        line-height:30px;
        text-align: ${props => props.side ? props.side : "center"}
    }
    
    /* Style for the headers in text */
    &>*{
        color: #ffffff;
        text-shadow: 0 0 20px black;
        font-weight:normal;
        font-size:34px;
        padding: calc(2% + 5px) 4% 0px;

        @media ${device.mobile}{
        /* padding: 5%; */
        font-size: min(10vw, 26px);
        } 
    }
    & a{
        text-decoration:none;
    }

    /*Highlighted text*/
    & span{
        color: #e9c7ff;
        /* font-weight: bold; */
        text-shadow: 0 0 0 black;
    }

    @media ${device.mobile}{
        width:80%;
     
        & p{
            /* font-size:${props => props.mobileFontSize ? `max(${props.mobileFontSize}, 5vw), 25px)` : "min(5vw, 10px)"}; */
            font-size: ${props => props.mobile_size ? props.mobile_size : "15px"};
            padding: 15px;
            line-height:30px;
        }
        ${props => {return props.styles}}; 
    }


    /**If animated, the initial styles are set. */
    ${props => {
        if (props.animated) {
            return css`
            opacity: 0;`
        }
    }}

    ${props => {
        if (props.beginAnimation) {
            return css`
            transition: opacity 0.7s ease-out;
            opacity: 100;
    ` }
    }}

    ${props => {return props.styles}};
`



/**Light version of the textContainer, used with a p tag and optional h header tag containing text inside.  */
export const LightTextContainer = styled(TextContainer)`
    width:80vw;
    max-width:850px;
    /* background-color:#00000024; */
    /* background-color:#1f1f1f; */
    /* box-shadow: 0px 0px 50px 2px #ffffff3b; */
    & p{
        color: #ffffff;
        text-shadow: 0px 0px 15px black;
    }

    /* Style for the headers */
    &>*{
        color: #ffffff;
        text-shadow: 0 0 20px black;
        font-weight:normal;
        font-size:34px;
        padding: calc(2% + 5px) 4% 0px;

        @media ${device.mobile}{
            font-size: min(10vw, 26px);
        } 
    }
    /* Style for the hightlighted text */
    & span{
        color: #e9c7ff;
        /* font-weight: bold; */
        text-shadow: 0 0 0 black;
    }

    ${props => {return props.styles}};
    `

/**Transparent version of the textContainer, used with a p tag and optional h header tag containing text inside.  */
export const TransparentTextContainer = styled(TextContainer)`

    /* background-color:#00000030; */
    /* background-color:#161616;     */
    /* border-radius: 0; */

    & p{
        color: #ffffff;
        font-size:18px;
        padding: 25px;
        text-shadow: 0px 0px 15px black;
    }
    box-shadow: 0px 0px 50px 5px #ffffff3b;

    @media ${device.mobile}{
        margin-bottom:0px;
    } 

`

//Specific Text Implementations
//////////////////////////////////////////////////////////////////////////////////////////////////
/** Text for the download, used with a p tag and optional h header tag containing text inside. */
export const DownloadText = styled(LightTextContainer)`

    margin-top:15px;
    min-width:120px;
    width: 50vw;
    max-width: 340px;
    display:flex;
    align-items:center;
    justify-content:center;

    @media ${device.mobile}{
        margin-top:40px;
        margin-bottom: 40px;
     
        
        ${props => {return props.styles}};
    }
    ${props => {return props.styles}};
`

export const EmailText = styled(LightTextContainer)`
    margin:0;
    width:100%;
    @media ${device.mobile}{
        width:100%;
        & p{
            font-size: max(18px,2.5vw);
        }
    }
`