import React from 'react'
import { ContactButton } from "../../components/button/button-style"
import "../../styles/text_styles.css"
import "./contact.css"


export default function ContactUsPage() {

  
  return (
    <div className="page_container">
        {/* <div className="flex_side">

            <div className="t_block_container single">
                <h3>Real Estate Visualization</h3>
                <br></br>
                <p className="t_block_text"> 
                Using state of the art technology, we can bring your real estate projects to life before the first brick is even laid. 
                </p>
            </div>

            <img className="image_block" src={require("../../images/general-images/tour.jpg")}>
            </img>
        </div> */}

        <div className="t_block_container centered" id="contact">
          <p>Have questions you want to ask? 
            <br></br>
            Looking to work with us to bring your dream VR app to life?
            <br></br>
            <br></br>


            Email us at: <span>contact.lumenviz@gmail.com</span> 

          </p>
  
        </div>
    
    </div>
  )
}
