import React from 'react'
import { ContactButton } from "../../components/button/button-style"
import "../../styles/text_styles.css"

export default function ApplicationsPage() {

  
  return (
    <div className="page_container">
        <div className="flex_side">
            <div className="t_block_container single">
                <h3>Custom VR Applications</h3>
                <br></br>
                <p className="t_block_text"> 
                Do you have a special <span>VR application</span> that you need developed? 
                <br></br>
                <br></br>
                Whether its an app for <span>training</span> employees, <span>simulating</span> work or just pure <span>entertainment</span>, we've got you covered. 
                </p>
            </div>


            <img className="image_block" src={require("../../images/general-images/customvr.jpg")}>
            </img>
        </div>
        <div className="t_block_container">
        <p className="t_block_text">Our team can bring make your dream <span>VR application to reality</span>.</p>
        <p>Simply contact us and we'd be happy to discuss how we can help you.</p>
        </div>
        <ContactButton to="/ContactUs">Contact Us</ContactButton>
    </div>
  )
}
